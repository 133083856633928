/**
 * ユーザー一斉メール送信クラス
 */
(function () {
    this.ManageBulkMail = (function () {
        function ManageBulkMail() {
            this.init();
        }

        ManageBulkMail.prototype.init = function () {
            /**
             * Vueオブジェクト
             * @type {Vue}
             */
            var app = new Vue({
                el: '[data-view="manage_content"]',
                /**
                 * ミックスインオブジェクト
                 */
                mixins: [
                    CommonMixin,
                ],
                /**
                 * コンポーネント群
                 */
                components: {
                    'message-sample-modal': this.messageSampleModal(),
                },

                /**
                 * データ群
                 */
                data: function () {
                    return {
                        sid: '', // 招待状ID
                        mail_title: '',
                        mail_content: '',
                    }
                },
                /**
                 * インスタンス生成時に実行される処理
                 */
                created: function () {

                },
                /**
                 * 画面描画後に実行される処理
                 */
                mounted: function () {
                    this.Common = new Common();

                    $.ajaxSetup({
                        type: 'POST',
                        dataType: 'json',
                        cache: false,
                        timeout: 30000, // 30秒
                    });

                },
                /**
                 * 各種メソッド
                 */
                methods: {
                    /**
                     * 例文選択モーダル表示
                     */
                    openMessageSampleModal: function () {
                        this.$refs.messageSampleModal.open();
                    },

                    submit: function() {
                        if($('select[name=category] option:selected').attr('data-cnt') !== '0') {
                            var $form = $('#send_bulk_mail_form');
                            $form.find('.error_message').empty();
                            $form.find('.error_message').removeClass('errors');

                            this.Common.ajaxConfirmFormSubmit($form, 'ゲストへ一斉メール送信を行います。よろしいですか？', {
                                success: function (data, textStatus, xhr) {
                                    swalToast('メールを送信しました', {icon: 'success'});
                                }
                            });
                        }else{
                            swalToast('送信先人数が0人です', {icon: 'warning'});
                        }
                    },

                    preview: function () {
                        var $form = $('#send_bulk_mail_form');
                        this.mail_title = $form.find('input[name="mail_title"]').val();
                        this.mail_content = $form.find('textarea[name="mail_content"]').val();

                        $('[data-remodal-id="preview_modal"]').remodal({
                            hashTracking: false,
                            modifier: 'l-remodal remodal-base preview_modal', // classをオーバーライド
                        }).open();
                    }
                }
            });
        };


        /**
         * 例文選択モーダルのVueコンポーネント
         */
        ManageBulkMail.prototype.messageSampleModal = function () {
            return {
                /**
                 * テンプレート
                 */
                template: '#manage-bulk-mail-message-sample-modal-template',

                /**
                 * ミックスインオブジェクト
                 */
                mixins: [
                    CommonMixin,
                ],
                /**
                 * データ群
                 */
                data: function () {
                    return {
                        sampleMessages: [], //例文一覧
                        modal: null, // remodalオブジェクト
                    }
                },
                /**
                 * インスタンス生成時に実行される処理
                 */
                created: function () {
                    this.invitation_url = document.querySelector('[data-roll="invitation_url"]').innerHTML;
                    this.invitation_title = document.querySelector('[name="invitation_title"]').innerHTML;
                    this.invitation_type = document.querySelector('[name="invitation_type"]').innerHTML;
                    this.invitation_date = document.querySelector('[name="invitation_date"]').innerHTML;
                    this.invitation_time1 = document.querySelector('[name="invitation_time1"]').innerHTML;
                    this.invitation_time2 = document.querySelector('[name="invitation_time2"]').innerHTML;
                    this.invitation_time3 = document.querySelector('[name="invitation_time3"]').innerHTML;
                    this.invitation_time4 = document.querySelector('[name="invitation_time4"]').innerHTML;
                    this.invitation_place = document.querySelector('[name="invitation_place"]').innerHTML;

                    this.mail_text = '先日よりご案内しておりました結婚パーティーの開催日が近づいてまいりましたので、ご出席いただく皆さまに当日の詳細をお知らせいたします。\n\n';

                    if(this.invitation_date.length){
                        this.mail_text = this.mail_text+'[ 開催日 ] ' + this.invitation_date + '\n';
                    }
                    if (this.invitation_type == 0){ //挙式、披露宴
                        if(this.invitation_time1.length){
                            this.mail_text = this.mail_text+'[ 挙式の受付 ] ' + this.invitation_time1 + '\n';
                        }
                        if(this.invitation_time2.length){
                            this.mail_text = this.mail_text+'[ 挙式の開始 ] ' + this.invitation_time2 + '\n';
                        }
                        if(this.invitation_time3.length){
                            this.mail_text = this.mail_text+'[ 披露宴の受付 ] ' + this.invitation_time3 + '\n';
                        }
                        if(this.invitation_time4.length){
                            this.mail_text = this.mail_text+'[ 披露宴の開始 ] ' + this.invitation_time4 + '\n';
                        }
                    } else if(this.invitation_type == 2){ //会費制、２次会
                        if(this.invitation_time1.length){
                            this.mail_text = this.mail_text+'[ 受付時間 ] ' + this.invitation_time1 + '\n';
                        }
                        if(this.invitation_time2.length){
                            this.mail_text = this.mail_text+'[ 開宴時間 ] ' + this.invitation_time2 + '\n';
                        }
                    }
                    if(this.invitation_place.length){
                        this.mail_text = this.mail_text+'[ 場所 ] ' + this.invitation_place + '\n';
                    }

                    this.mail_text = this.mail_text+'詳細は下記Web招待状のURLをご確認ください。\n';
                    this.mail_text = this.mail_text+'[ URL ] ' + this.invitation_url + '\n'+
                        '\n\n' +
                        'どうぞ、お気をつけてお越しください。\n' +
                        '皆さまにお会いできることを、楽しみにしております。\n' +
                        '\n' +
                        '\n\n';

                    // メール本文の定型文
                    this.mail_template = {};
                    this.mail_template[0] = {
                        'label':'結婚式前のご連絡',
                        'content':this.mail_text
                    };

                    this.mail_text = 'この度は、わたしたちの結婚パーティーにご出席いただき、ありがとうございました。\n\n' +
                        '皆さまの温かい祝福に包まれ、新たなる門出を迎えることができました。\n' +
                        'これからは二人で協力して幸せな家庭を築いていきます。\n\n' +
                        '引き続き、末長くお付き合いくださいますよう、お願いいたします。';

                    this.mail_template[1] = {
                        'label':'結婚式後のお礼',
                        'content':this.mail_text
                    };
                    this.sampleMessages = this.mail_template;

                },
                    /**
                 * 画面描画後に実行される処理
                 */
                mounted: function () {
                    // モーダルの初期化
                    this.modal = $(this.$el).remodal({
                        hashTracking: false,
                        modifier: 'l-remodal remodal-base message-sample-modal', // classをオーバーライド
                    });
                },

                /**
                 * 各種メソッド
                 */
                methods: {
                    /**
                     * モーダルを開く
                     */
                    open: function () {
                        this.modal.open();
                    },
                    selectMessageSample: function(index) {
                        var that = this;
                        vex.dialog.confirm({
                            message: '既に設定されている案内文を上書きします。よろしいですか？',
                            callback: function (value) {
                                if (value === true) {
                                    $('textarea[name="mail_content"]').val(that.sampleMessages[index]['content']);
                                    that.modal.close();
                                }
                            }
                        });
                    },
                }
            };
        };

        return ManageBulkMail;
    })();
}).call(this);
